// import Blink from 'react-blink-text';
import React, { useState, useEffect } from "react";
const width = 500;
const height = 600;

const aspectRatio = width / height; // Original width/height aspect ratio

const rectWidth = 447;
const rectHeight = 598;
const rectAspectRatio = rectWidth / rectHeight; // Original width/height aspect ratio

const texts = ["inkfinite", "coming soon"];

const blackDelay = 175;
const greyDelay = 2500;

const epaperGrey = "#dddddd";

const blackFrameImage = require("./large_frame.png");

const BWFrame = () => {
  const [currentBox, setCurrentBox] = useState("grey");
  const [text, setText] = useState("");
  const [textOpacity, setTextOpacity] = useState(1); // New state for text opacity

  const [constraint, setConstraint] = useState("vh");

  useEffect(() => {
    // Adjust constraint based on the window aspect ratio
    const updateConstraint = () => {
      const windowAspectRatio = window.innerWidth / window.innerHeight;
      setConstraint(windowAspectRatio < aspectRatio ? "vw" : "vh");
    };

    // Initial check and on resize
    updateConstraint();
    window.addEventListener("resize", updateConstraint);
    return () => window.removeEventListener("resize", updateConstraint);
  }, []);

  useEffect(() => {
    const sequence = async () => {
      while (true) {
        // Loop indefinitely

        // 1. Start with grey box with no text
        setCurrentBox("grey");
        setText("");
        await new Promise((resolve) => setTimeout(resolve, 750));

        // 2. Flash to black box with white text
        setCurrentBox("black");
        setText(texts[0]);
        await new Promise((resolve) => setTimeout(resolve, blackDelay));

        // 3. Flash back to grey box with black text
        setCurrentBox("grey");
        setText(texts[0]);
        await new Promise((resolve) => setTimeout(resolve, greyDelay)); // Pause for 60 seconds

        // 4. Flash to black box with no text
        setCurrentBox("black");
        setText("");
        setTextOpacity(0.05); // Set text opacity to 5%
        await new Promise((resolve) => setTimeout(resolve, blackDelay));

        // 5. Flash to grey box with light text
        setCurrentBox("grey");
        setText(texts[0]);
        await new Promise((resolve) => setTimeout(resolve, greyDelay)); // Pause for 60 seconds

        // 6. Flash to black box with no text
        setCurrentBox("black");
        setText("");
        setTextOpacity(1); // Set text opacity to 50%
        await new Promise((resolve) => setTimeout(resolve, blackDelay));

        // 5. Flash to grey box with no text
        setCurrentBox("grey");
        setText("");
        await new Promise((resolve) => setTimeout(resolve, greyDelay)); // Pause for 60 seconds

        // 6. Flash to black box with new text
        setCurrentBox("black");
        setText(texts[1]);
        await new Promise((resolve) => setTimeout(resolve, blackDelay));

        // 7. Flash to grey box with black text
        setCurrentBox("grey");
        setText(texts[1]);
        await new Promise((resolve) => setTimeout(resolve, greyDelay));

        // 4. Flash to black box with no text
        setCurrentBox("black");
        setText("");
        setTextOpacity(0.05); // Set text opacity to 5%
        await new Promise((resolve) => setTimeout(resolve, blackDelay));

        // 5. Flash to grey box with light text
        setCurrentBox("grey");
        setText(texts[1]);
        await new Promise((resolve) => setTimeout(resolve, greyDelay)); // Pause for 60 seconds

        // 6. Flash to black box with no text
        setCurrentBox("black");
        setText("");
        setTextOpacity(1); // Set text opacity to 50%
        await new Promise((resolve) => setTimeout(resolve, blackDelay));
      }
    };

    sequence();
  }, []);

  return (
    <div>
      <div
        style={{
          top: "50%", // Center vertically
          left: "50%", // Center horizontally
          transform: "translate(-50%, -50%)", // Adjust for centering
          width: constraint === "vh" ? `calc(45${constraint} * ${rectAspectRatio})` : "40vw",
          height: constraint === "vh" ? "45vh" : `calc(40vw / ${rectAspectRatio})`,
          backgroundColor: currentBox === "grey" ? epaperGrey : "black",
          color: currentBox === "grey" ? "black" : epaperGrey,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: constraint === "vh" ? `calc(45${constraint} * ${text === texts[0] ? 0.15 : 0.1})` : `calc(40vw * ${text === texts[0] ? 0.2 : 0.02/0.15})`, // Font size based on width
          transition: "background-color 0.25s, color 0.02s",
          textAlign: "center",
          position: "absolute",
          zIndex: 0,
        }}
      >
        <span
          style={{
            opacity: textOpacity, // Apply text opacity
            transition: "opacity 0.1s", // Smooth opacity transition
          }}
        >
          {text}
        </span>
      </div>
      <img
        src={blackFrameImage} // Replace with your image URL
        alt="FrameOverlay"
        style={{
          position: "absolute",
          top: "50%", // Center vertically
          left: "50%", // Center horizontally
          transform: "translate(-50%, -50%)", // Adjust for centering
          width: constraint === "vh" ? `calc(65${constraint} * ${aspectRatio})` : "65vw",
          height: constraint === "vh" ? "65vh" : `calc(65vw / ${aspectRatio})`,
          zIndex: 1, // Ensure the image is above the rectangle
        }}
      />
    </div>
  );
};

export default BWFrame;
