import "./App.css";
import BWFrame from "./bwAnimation";
import React, { useState, useEffect } from "react";

function App() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    // Update state when window is resized
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Attach event listener
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      style={{
        width: windowSize.width * 0.8, // Adjust size as desired
        height: windowSize.height * 0.8, // Adjust size as desired.
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "auto",
        overflow: "hidden", // Prevent scrolling
      }}
    >
      <BWFrame />
    </div>
  );
}

export default App;
